// src/App.js
import React from 'react';
import BouncingImage from './BouncingImage';

function App() {
  const images = [
    'https://atomichub-ipfs.com/ipfs/QmQeNApDaDUQC76UydNJ9fYYyukLrfFJZGoTRyY3oUzcYe',
    'https://atomichub-ipfs.com/ipfs/QmbL1E7ydwVYyZ6uRMekEko5U2Q7RT1F4y58R2iirN1YRb',
"https://atomichub-ipfs.com/ipfs/QmasyQ2cojM5NP6H9iG3f3EXCTmBHK4X4QeXDEWVqf42Sh",
  "https://atomichub-ipfs.com/ipfs/QmbLSxcRFRmVQkxxK97zFkV1k4LWPwNKQEnbyiMKZuTy3m",
"https://atomichub-ipfs.com/ipfs/QmUzD8YZm3RXAM8XZuECjUvv3nAghgtgaQsd59vJLkkhLz",
"https://atomichub-ipfs.com/ipfs/QmVPi4LzEUdptUhd3fyMeiJgLfX8namDQLRShiS2dBcKt7",
"https://atomichub-ipfs.com/ipfs/QmdfnL3Pecqa6Nmm1K6RquUXCV9sBxFaPJPoT1F2Jp9M9T",
"https://atomichub-ipfs.com/ipfs/QmR8CKYxMTNrw41mqJsVhnD32zmxrWRcN4KWJywRpP1mmF",
"https://atomichub-ipfs.com/ipfs/QmPLQ9nM8jZDv73xTVbuHh3j877PLNrpUQb6fgDKyGZLFZ",
"https://atomichub-ipfs.com/ipfs/QmVyBb1JQVDd4W28rjbuqhxgaxP4mXJZURQ79anYrcJyMR",
]
  return (
    <div className="App">
      {images.map((src, index) => (
        <BouncingImage key={index} src={src} />
      ))}
    </div>
  );
}

export default App;
