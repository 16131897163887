// src/BouncingImage.js
import React, { useState, useEffect } from 'react';

function BouncingImage({ src }) {
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
    dx: 0,
    dy: 0,
    size: 0
  });

  useEffect(() => {
    const size = Math.max(2, Math.random() * 10); // Random size between 2% and 10%
    const x = Math.random() * (100 - size); // Random x position
    const y = Math.random() * (100 - size); // Random y position
    const dx = Math.random() * 2 - 1; // Change in x (velocity)
    const dy = Math.random() * 2 - 1; // Change in y (velocity)

    setPosition({ x, y, dx, dy, size });

    const move = setInterval(() => {
      setPosition(prev => {
        let newDx = prev.dx;
        let newDy = prev.dy;
        let newX = prev.x + prev.dx;
        let newY = prev.y + prev.dy;

        // Reverse direction on x-axis if hitting edge
        if (newX < 0 || newX > 100 - prev.size) {
          newDx = -prev.dx;
          newX = newX < 0 ? 0 : 100 - prev.size;
        }

        // Reverse direction on y-axis if hitting edge
        if (newY < 0 || newY > 100 - prev.size) {
          newDy = -prev.dy;
          newY = newY < 0 ? 0 : 100 - prev.size;
        }

        return { ...prev, x: newX, y: newY, dx: newDx, dy: newDy };
      });
    }, 50);  // Reduce this time to make movement smoother

    return () => clearInterval(move);
  }, [src]);

  const { x, y, size } = position;
  return (
    <img src={src} alt="Bouncing" style={{
      position: 'absolute',
      width: `${size}vw`,
      height: `${size}vw`,
      top: `${y}vh`,
      left: `${x}vw`,
      transition: 'top 50ms linear, left 50ms linear' // Smoother transition
    }} />
  );
}

export default BouncingImage;
